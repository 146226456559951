import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "D:/a/1/s/kope-docs-sdk/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`An Introduction to Activities`}</p>
    </PageDescription>
    <p>{`KOPE Activities are essentially just C# methods. In order to register a C# method as a `}<strong parentName="p">{`KOPE Activity`}</strong>{`, certain rules must be followed:`}</p>
    <AnchorLinks mdxType="AnchorLinks">
    <AnchorLink mdxType="AnchorLink">1. Activities Must Use the [kopeActivity] Attribute</AnchorLink>
    <AnchorLink mdxType="AnchorLink">2. Activities Must Have Unique Names</AnchorLink>
    <AnchorLink mdxType="AnchorLink">3. Activities Must Be Static Methods</AnchorLink>
    <AnchorLink mdxType="AnchorLink">4. Methods Must Return a Value</AnchorLink>
    </AnchorLinks>
    <h2>{`1. Activities Must Use the `}{`[kopeActivity]`}{` Attribute`}</h2>
    <p>{`Any method intended to be used as a KOPE Activity must be tagged with the `}<inlineCode parentName="p">{`kopeActivity`}</inlineCode>{` attribute. Any method without the attribute will be disregarded when generating the KOPE manifest file.`}<br /><br /></p>
    <pre><code parentName="pre" {...{
        "className": "language-cpp"
      }}>{`using kope.Workflows.SDK;
using System;

namespace kope.Demo{

    public static class AwesomeFunctions{

        [kopeActivity]
        public static bool AreStringsEqual(string text1, string text2)
            => text1 == text2;
    }
}
`}</code></pre>
    <h2>{`2. Activities Must Have Unique Names`}</h2>
    <p>{`To prevent Activity naming clashing, every Activity name from the same project must be unique. For overload methods, the optional `}<inlineCode parentName="p">{`Name`}</inlineCode>{` parameter from `}<inlineCode parentName="p">{`kopeActivity`}</inlineCode>{` attribute can be used.`}<br /><br /></p>
    <pre><code parentName="pre" {...{
        "className": "language-cpp"
      }}>{`using kope.Workflows.SDK;
using System;

namespace kope.Demo{

    public static class AwesomeFunctions{

        [kopeActivity]
        public static bool AreStringsEqual(string text1, string text2)
            => text1 == text2;

        [kopeActivity(Name = "AreStringsEqualCasing")]
        public static bool AreStringsEqual(string text1, string text2, bool ignoreCase)
        {
            var compare = ignoreCase
                ? StringComparison.InvariantCultureIgnoreCase
                : StringComparison.InvariantCulture;

            return text1.Equals(text2, compare);
        }
    }
}
`}</code></pre>
    <h2>{`3. Activities Must Be Static Methods`}</h2>
    <p>{`At the time of writing, C# methods must be static to be eligible as KOPE Activities. This is to avoid needing to create an instance of the containing class to execute a given method. The containing class doesn’t need to be static.`}</p>
    <br /><br />
    <p>{`To allow non static methods would require to generate additional Activity for class constructors, a feature that might be implemented in the future.`}<br /><br /></p>
    <pre><code parentName="pre" {...{
        "className": "language-cpp"
      }}>{`using kope.Workflows.SDK;
using System;

namespace kope.Demo{

    public static class AwesomeStaticClass{

        [kopeActivity]
        public static bool IsGreaterThan(this int input, double value)
            => input > value;
    }

    public class AwesomeNonStaticClass{

        [kopeActivity]
        public static bool AreStringsEqual(string text1, string text2)
            => text1 == text2;
    }

}
`}</code></pre>
    <h2>{`4. Methods Must Return a Value`}</h2>
    <p>{`Any method identified as a KOPE Activity must return a concrete type. `}<inlineCode parentName="p">{`Void`}</inlineCode>{` returns are not allowed.`}<br /><br /></p>
    <pre><code parentName="pre" {...{
        "className": "language-cpp"
      }}>{`using kope.Workflows.SDK;

namespace kope.Demo{

    public static class AwesomeFunctions{

        // Below activity will throw an error and prevent from compiling project
        // [kopeActivity]
        // public static void DoNothingFunction(string text1, string text2)
        // {

        // }
    }

}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      